<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<template>
  <login logo="/img/login.svg" image="/img/login-background.jpg">
    <template #login-actions>
      <div id="iiotResetPassword">
        <v-form id="iiotResetPasswordLeftPart" ref="form" @keyup.enter.native="reset">
          <div class="title mb-2">
            <h1>{{ $t('login.resetPassword.title') }}</h1>
            <v-divider class="mt-1" />
          </div>
          <v-row class="justify-center">
            <v-text-field
              id="iiotResetPasswordNewPassword"
              v-model="newPass"
              :label="$t('login.resetPassword.newPass')"
              :required="true"
              type="password"
              :rules="[resetPassRules.required, resetPassRules.new_password, resetPassRules.passwordLength]"
              class="default-input mt-16"
              validate-on-blur
            />
          </v-row>
          <v-row class="justify-center">
            <v-text-field
              id="iiotResetPasswordConfirmPassword"
              v-model="confirmPass"
              :label="$t('login.resetPassword.confirmPass')"
              :required="true"
              type="password"
              :rules="[resetPassRules.required, resetPassRules.compare, resetPassRules.passwordLength]"
              class="default-input"
              validate-on-blur
            />
          </v-row>
          <v-col lg="7" md="7" sm="4" offset-md="2" offset-sm="4">
            <v-row class="justify-center mt-10">
              <nerve-button
                id="iiotResetPasswordResetButton"
                :disabled="!validateInput || alreadyClicked"
                :text="$t('login.resetPassword.resetBtn')"
                type-of-btn="action"
                size="normal"
                @click-event="reset()"
              />
            </v-row>
          </v-col>
        </v-form>
      </div>
    </template>
  </login>
</template>
<script>
import { Login, NerveButton } from 'nerve-ui-components';
import { VALIDATION_REGEX, RESET_PASSWORD } from '@/constants';

export default {
  components: { Login, NerveButton },
  data() {
    return {
      newPass: '',
      confirmPass: '',
      alreadyClicked: false,
      hash: '',
    };
  },
  computed: {
    validateInput() {
      return (
        this.newPass !== '' &&
        this.confirmPass !== '' &&
        VALIDATION_REGEX.NEW_PASSWORD.test(this.newPass) &&
        this.newPass === this.confirmPass
      );
    },
    resetPassRules() {
      return {
        required: (v) => !!v || 'Required',
        new_password: (v) => VALIDATION_REGEX.NEW_PASSWORD.test(v) || this.$t('login.resetPassword.patternRule'),
        compare: (v) => this.newPass === v || this.$t('login.resetPassword.compareRule'),
        passwordLength: (v) =>
          v.length >= RESET_PASSWORD.MIN_LENGTH ||
          this.$t('baseForm.passwordMinLengthMsg', { msgLength: RESET_PASSWORD.MIN_LENGTH }),
      };
    },
  },
  async created() {
    try {
      // eslint-disable-next-line prefer-destructuring
      this.hash = window.location.pathname.split('/resetPassword/')[1];
      if (!this.hash) {
        this.$router.push({ name: 'Login' });
      }
      await this.$store.dispatch('reset-password/resetHashExists', this.hash);
      const isHashExists = this.$store.getters['reset-password/resetHashExists'];
      if (!isHashExists) {
        await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: this.$t('login.resetPassword.invalidLink'),
          color: 'red',
          showClose: true,
        });
        setTimeout(() => {
          this.$router.push({ name: 'Login' });
        }, 1200);
      }
    } catch (e) {
      this.$log.debug(e);
    }
  },
  methods: {
    async reset() {
      try {
        if (!this.$refs.form.validate()) {
          return;
        }
        await this.$store.dispatch('reset-password/resetPassword', {
          confirm: this.confirmPass,
          secret: this.newPass,
          hash: this.hash,
        });
        await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: this.$t('login.resetPassword.changedPassword'),
          color: 'success',
          showClose: true,
        });
        setTimeout(() => {
          this.$router.push({ name: 'Login' });
        }, 1200);
      } catch (e) {
        this.$log.debug(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#iiotResetPasswordLeftPart {
  padding: 70px;
  .title {
    > h1 {
      text-align: center;
    }
    > .v-divider {
      margin-left: 45%;
    }
  }
}
</style>
